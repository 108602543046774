import styles from './Wrapper.module.css'


type WrapperTypes = {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  paddingTop?: 0 | 120 | 150;
  gap?: 0 | 25 | 35 | 50 | 120;
}

export default function Wrapper({ children, className = '', paddingTop = 0, gap = 0}: WrapperTypes) {
  return (
    <section
      className={`
        ${styles.Wrapper} ${className} 
        ${styles[`p-t-${paddingTop}`]}
        ${ gap !== 0 ? styles[`Wrapper-gap--${gap}`]: '' }
      `}
    >
      {children}
    </section>
  )
}

module.exports = {
    "safteyTitle": {
        sv: 'Hur gör man?',
        en: 'How to',
        de: 'Wie macht man das?',
        es: '¿Cómo se hace?',
        dk: 'Hvordan gør du?',
        fr: 'Comment procédez-vous ?' 
    },
    "closeShore": {
        sv: 'Håll dig nära stranden och håll dig borta från motorbåtsleder. Om du paddlar SUP, fäst alltid fotkopplet.',
        en: 'Stay close to the shore and stay out of motorboat paths. Always attach the wrist tether connected to the paddle, and if you are paddling on a SUP be sure to attach the ankle tether as well.',
        de: 'Bleiben Sie in Ufernähe und halten Sie sich von Motorbooten fern. Wenn Sie mit dem SUP paddeln, befestigen Sie immer die Fußleine.',
        es: 'Mantente cerca de la orilla y aléjate de los caminos de las lanchas motoras. Si estás remando en SUP, coloca siempre la correa para los pies.',
        dk: 'Hold dig tæt på kysten, og hold dig væk fra motorbåde. Hvis du padler SUP, skal du altid fastgøre sikkerhedsline.',
        fr: 'Restez près du rivage et n\'approchez pas des bateaux à moteur. Si vous pagayez en SUP, attachez toujours le laisse-pieds.' 
    },
    "lifeJacket": {
        sv: 'Bär alltid flytväst och överskrid inte dess viktgräns.',
        en: 'Always wear a life jacket and do not exceed its weight capacity.',
        de: 'Tragen Sie immer eine Schwimmweste und überschreiten Sie nicht deren Gewichtskapazität.',
        es: 'Lleve siempre puesto un chaleco salvavidas y no supere su capacidad de peso.',
        dk: 'Bær altid en svømmevest, og overskrid ikke dens vægtkapacitet.',
        fr: 'Portez toujours un gilet de sauvetage et ne dépassez pas sa capacité de poids.' 
    },
    "paddleCompany": {
        sv: 'Paddla med sällskap eller se till att du behärskar självräddning.',
        en: 'Paddle with company, or make sure you are confident with self-rescue.',
        de: 'Paddelt in Begleitung oder stellt sicher, dass ihr mit der Selbstrettung vertraut seid.',
        es: 'Rema acompañado o asegúrate de que sabes rescatarte a ti mismo.',
        dk: 'Padl med selskab, eller sørg for, at du er sikker på, at du kan redde dig selv.',
        fr: 'Pagayez en compagnie de quelqu\'un d\'autre ou assurez-vous que vous savez vous secourir vous-même.' 
    },
    "weatherForcast": {
        sv: 'Bär kläder efter väder. Kolla upp väderprognosen innan och planera din rutt därefter. Paddla inte när vattnet är för kallt eller när det är för blåsigt.',
        en: 'Wear clothing appropriate to the conditions. Check the weather forecast before going out and plan your route accordingly. Do not paddle when the water is too cold or when it\'s too windy.',
        de: 'Trage den Bedingungen entsprechende Kleidung. Vor der Tour sollte man sich über die Wettervorhersage informieren und seine Route entsprechend planen. Paddle nicht, wenn das Wasser zu kalt ist oder wenn es zu windig ist.',
        es: 'Lleve ropa adecuada a las condiciones. Consulta la previsión meteorológica antes de salir y planifica tu ruta en consecuencia. No reme cuando el agua esté demasiado fría o haga demasiado viento.',
        dk: 'Bær tøj, der passer til forholdene. Tjek vejrudsigten, inden du tager ud, og planlæg din rute i overensstemmelse hermed. Du må ikke padle, når vandet er for koldt, eller når det blæser for meget.',
        fr: 'Portez des vêtements adaptés aux conditions. Vérifiez les prévisions météorologiques avant de sortir et planifiez votre itinéraire en conséquence. Ne pagayez pas lorsque l\'eau est trop froide ou lorsqu\'il y a trop de vent.' 
    },
    "afterDark": {
        sv: 'Paddla inte efter mörkrets inbrott. Paddla inte när du är berusad.',
        en: 'Do not paddle after dark. Do not paddle while intoxicated.',
        de: 'Paddelt nicht nach Einbruch der Dunkelheit. Paddelt nicht unter dem Einfluss von Alkohol oder Drogen.',
        es: 'No reme de noche. No reme en estado de embriaguez.',
        dk: 'Du må ikke padle efter mørkets frembrud. Du må ikke padle i beruset tilstand.',
        fr: 'Ne pas pagayer après la tombée de la nuit. Ne pas pagayer en état d\'ébriété.' 
    },
    "ownRisk": {
        sv: 'Du paddlar alltid på egen risk. Träna självräddning och ombordstigning innan du ger dig ut. För sit-in-kajaker tillhandahålls en flottör.',
        en: 'You always paddle at your own risk. Practice self-rescue and re-boarding before going out.  For sit-in kayaks, a paddle float is provided.',
        de: 'Du paddelst immer auf eigene Gefahr. Übe die Selbstrettung und das Wiedereinsteigen, bevor du losfährst.  Für Sitzkajaks wird ein Paddelschwimmer zur Verfügung gestellt.',
        es: 'Reme siempre por su cuenta y riesgo. Practique el autorrescate y el reembarque antes de salir.  Para los kayaks sentados, se proporciona un flotador de remo.',
        dk: 'Du padler altid på eget ansvar. Øv dig i at redde dig selv og gå om bord igen, før du tager ud.  I alle kajakker er der en pagajflyder til rådighed.',
        fr: 'Vous pagayez toujours à vos propres risques. Entraînez-vous à l\'autosauvetage et à la remontée à bord avant de sortir.  Pour les kayaks assis, un flotteur est fourni.' 
    },
    "approveSaftey": {
        sv: 'Jag har läst och förstått säkerhetsriktlinjerna',
        en: 'I have read and understand the safety guidelines',
        de: 'Ich habe die Sicherheitsrichtlinien gelesen und verstanden',
        es: 'He leído y comprendo las directrices de seguridad',
        dk: 'Jeg har læst og forstået sikkerhedsforskrifterne',
        fr: 'J\'ai lu et compris les consignes de sécurité' 
    }
}
type ContentWrapperTypes = {
  width?: string
  margin?: string
  padding?: string
  backgroundColor?: string
  children: string | JSX.Element | JSX.Element[]
}

export default function ContentWrapper({
  width,
  margin,
  padding,
  children,
  backgroundColor,
}: ContentWrapperTypes) {
  return (
    <div style={{ margin, padding, backgroundColor, width }}>{children}</div>
  )
}

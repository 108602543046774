import styles from './Paragraph.module.css'

type PTypes = {
  invert?: boolean
  size?: 'medium' | 'large'
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent'
  children: string | JSX.Element
  error?: boolean
  desktopSize?: 'medium' | 'large'
}

export default function P({
  size,
  children,
  textAlign = 'left',
  invert,
  error,
  desktopSize,
}: PTypes) {
  return (
    <p
      className={`
      ${styles.p} 
      ${size && styles[`p-${size}`]} ${error && styles.error}
      ${desktopSize && styles[`p-desktop-${desktopSize}`]}`}
      style={{ textAlign, color: invert && '#FFF' }}
      dangerouslySetInnerHTML={{ __html: children }}
    ></p>
  )
}
